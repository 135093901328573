*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root{
  --mainColor: #003366;
  --fontColor: #848484;
  --blackFontColor: #333333;
}
.navbar-toggler{
  border-width: 0!important;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
}
.navbar-toggler:active{
  border-width: 0!important;
}
.navbar-toggler-icon {
  width: 1em!important;
  height: 1em!important;
}
.navLogo{
  width: 220px;
}
/* li{
  list-style: none;
} */
.navbar-nav .nav-item {
  /* font-weight: 600; */
  font-size:14px;
}
section{
  padding: 0 8.5%;
}
a {
  text-decoration: none!important;
  color: rgb(0, 0, 0)!important;
}
.slashCarrier{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.logoName h3{
  font-weight: bolder;
  font-size: 44px;
}
.UsefulLink h4{
  font-size: 20px;
  font-weight: bold;
}
.emptydiv{
  width: 60% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 35px 10px 9%;
}
.banner {
  background-color: var(--mainColor); 
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  padding: 10px 35px 10px 6rem;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);
  color: white;
}
.banner-content {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  cursor: pointer;
}
.phone-number, .email{
  font-size: 12px;
}
.coureselImg{
  width: 100%;
  object-fit: cover;
  height: 83vh;
}


.carousel-caption {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  color: white;
}

.carousel-caption .small-text {
  font-size: 18px;
  letter-spacing: 0px;
}

.carousel-caption h1 {
  font-weight: bold;
  font-size: 3rem;
  line-height: 1.1;
}

/* Button styling */
.btn-light {
  background-color: transparent !important;
  font-size: 18px;
  border: none !important;
  color: #ffff !important;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.LearnMoreBTN{
  color: #ffff !important;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.arrow {
  font-size: 25px;
  color: #ffff !important;
  margin-top: -0.5%;
}
.discoverirgfont{
  color: var(--mainColor);
  font-size: 15px;
  font-weight: 550;
}
.discoverRigCarrier{
  padding: 5% 0;
}
.WeSpecialize{
  font-size: 44px;
  font-weight: 550;
  line-height: 1.2;
  padding: 1.5% 0;
}
.thekey{
  font-size: 18px;
  color: var(--fontColor);
}
.firstimageDiv{
  background-size: cover;
  background-position: center;
  height: 80vh; 
  background-image: url('/src/Images/home_two.png');
}
.homepage_carrrier{
  padding: 0 9%;
  color: #ffff;
  background-color: rgba(0, 0, 0, 0.5);
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.developmental{
  font-weight: bolder;
  color: #ffffff;
  font-size: 65px;
}

.weProvideCut{
  text-align: initial;
  font-size: 35px;
  font-weight: bold;
}
.paragraph{
  font-size: 18px;
  color: var(--fontColor);
}
.home-serv{
  font-size: 18px;
}
.viewMore{
  color: var(--mainColor) !important;
  font-size: 17px;
  font-weight: bold;
  text-align: center !important;
}
.viewMoreCarrier{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
  align-content: center;
  height: inherit !important;
}
.imageDiv1{
  height: 50vh;
  background-size: cover;
  background-position: center;
  background-image: url('/src/Images/services_one.jpg');
  border-radius: 10px;
}
.imageDiv2{
  height: 50vh;
  background-size: cover;
  background-position: center;
  background-image: url('/src/Images/services_two.png');
  border-radius: 10px;
}
.imageDiv3{
  height: 50vh;
  background-size: cover;
  background-position: center;
  background-image: url('/src/Images/services_three.png');
  border-radius: 10px;
}
.imageDiv4{
  height: 50vh;
  background-size: cover;
  background-position: center;
  background-image: url('/src/Images/services_four.jpg');
  border-radius: 10px;
}
.imageDiv5{
  height: 50vh;
  background-size: cover;
  background-position: center;
  background-image: url('/src/Images/services_five.jpeg');
  border-radius: 10px;
}

.imageDiv6{
  height: 50vh;
  background-size: cover;
  background-position: center;
  background-image: url('/src/Images/servcies_six.png');
  border-radius: 10px;
}
.imageDiv2layer{
  background-color: rgba(0, 0, 0, 0.5);
  height: inherit;
  border-radius: 10px;
}
.hover_effect{
  transition: transform 0.3s;
  cursor: pointer;
}
.hover_effect:hover {
  transform: scale(1.05);
}
.six_img{
  width: 100%;
}
.six_img img{
  object-fit: cover;
  width: 100%;
  border-radius: 9px;
}
.displayFlex{
  display: flex;
  flex-direction: column !important;
  justify-content: space-between !important;
  padding: 1rem;
  color: #ffff;
  height: inherit;
}
.wellInterventionTextCarrier{
  width: 100%;
}
.wellText{
  font-size: 25px;
  font-weight: bold;
}
.weldivpt{
  padding-top: 2rem !important;
}
.fontCarrier{
  font-size: 25px;
  font-weight: bold;
  float: right;
} 
.weMeet_paragraph{
  font-size: 60px;
  line-height: 1.1;
  font-weight: bold;
}
.image_div{
  width: 100% !important;
  height: 350px !important;
  margin-bottom: 5rem;
}

.image_carrier2{
  width: 85%;
  height: 100%;
  float: right;
}
.fontAndCarrier_carrier{
  padding: 3rem 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly !important;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.lineHeight{
  width: 2.5px;
  height: 100px;
  background-color: #ffff;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.aboutUs_weProvide p{
  color: #ffff;
  font-size: 29px;
  text-align: initial;
  font-weight: bold;
}
.fontIcon{
  font-size: 30px;
  color: #ffff;
  font-weight: 100 !important;
}
.rigFormText{
  width: 100%;
}
.text{
  font-size: 18px;
}
.rigText{
  font-size: 18px;
}
.grayColorDivCarrier{
  width: 100%;
  background-color: #F2F2F2;
}
.ourAimtext{
  padding: 4rem;
  font-size: 33px;
  color: var(--mainColor);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
}
.aboutfullImageDiv1{
  width: 100%;
  height: 65vh;
}
.aboutfullImageDiv1 img{
  width: 100%;
  height: inherit;
  object-fit: cover;
  border-radius: 8px;
}
.fullImageDiv{
  width: 100%;
}
.fullImageDiv img{
  width: inherit;
  object-fit: cover;
  height: 350px;
  border-radius: 8px;
}
.fullImageDiv1{
  width: 100%;
}
 .fullImageDiv1 img{
  width: inherit;
  object-fit: cover;
  height: 380px;
  border-radius: 8px;
}
.fullImageDiv20{
  width: 100%;
}
 .fullImageDiv20 img{
  width: inherit;
  object-fit: cover;
  height: 420px;
}
.fullImageDiv30{
  width: 100%;
}
 .fullImageDiv30 img{
  width: inherit;
  object-fit: cover;
  height: 580px;
}
.completionContactButtton{
  border-color: transparent;
  background: var(--mainColor);
  color: #ffff;
  padding: 5px 18px;
  border-radius: 5px;
}
.missionDivCarrier{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
.missionHeadi p{
  font-size: 18px;
}
.missionHeadi h2{
  color: var(--blackFontColor);
  font-size: 24px;
  font-weight: bold;
}
.marginAlignment{
  margin-top: -1.4%;
}
.footerCarrier{
  height: 40vh;
  width: 100%;
}
.footerImageCarrier{
  width: 100%;
  height: 100%;
  color: #ffff;
  background-size: cover;
  background-position: center;
  /* background-image: url('/src/Images/shridhar-thorat-6AKLKt-KmdY-unsplash.png'); */
  background-color: #003366;
}
.footerTextCarrier{
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 9%;
}
.usefulLinkDetail{
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}
.usefulLinkDetail span {
  font-size: 14px;
  cursor: pointer;
}
.wellparagraph{
  font-size: 15px;
}
.detailList{
  font-size: 16px !important;
}
.contactUsGap{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homepage{
  background-color: #F2F2F2;
  padding: 0.3rem 1rem;
  border-radius: 15px;
}

.sectionGap{
  margin: 2rem 0;
}

.half_img{
  width: 100%;
  margin: 5% 0;
}

.half_img_carrier{
  width: 100%;
  height: 88vh;
}
.half_img_carrier img{
  width: 100%;
  height: inherit;
  object-fit: cover;
  border-radius: 0px;
}
.margin_buttom{
  margin-bottom: 5%;
}
.mainColor{
  background-color: var(--mainColor) !important;
  border-color: var(--mainColor) !important;
}
.fullWidth{
  width: 100%;
}
.detailHeaders{
  padding-left: 17.5%;
}
.servicesMarginTop{
  margin-top: 13% !important;
}
.welservicesMarginTop{
  margin-top: 7%;
}
.wrapNav{
  padding: 0 7.8%;
}
/* .HwrapNav{
  padding: 0 4%;
} */

.usefulLinkDetail span a{
  color: #ffff !important;
}

@media screen and (max-width: 987px) {
  .slashCarrier{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .emptydiv{
    width: 40% !important;
  }
  .banner {
    background-color: var(--mainColor); 
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: right;
    padding: 10px 35px 10px 4rem;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
    color: white;
  }
  
.sectionGap{
  margin: 2rem 0;
}
  .banner-content {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    cursor: pointer;
  }
  .phone-number, .email{
    font-size: 12px;
  }
  .weMeet_paragraph {
    font-size: 30px;
    line-height: 1;
    font-weight: 500;
  }
.image_div {
  width: 100% !important;
  margin-bottom: 0;
 }
 .aboutUs_weProvide p{
  color: #ffff;
  font-size: 20px;
  text-align: initial;
  font-weight: bold;
 }
 .rigText {
  font-size: 15px !important;
}
.ourAimtext {
  padding: 4rem;
  font-size: 20px;
  color: var(--mainColor);
  font-weight: bold;
}
.aboutfullImageDiv1 {
  width: 100%;
  height: 25vh;
}
.missionHeadi h2 {
  color: var(--blackFontColor);
  font-size: 19px;
  font-weight: bold;
}
.fullImageDiv1 img {
  width: inherit;
  object-fit: cover;
  height: 100%;
}
.detailList{
  font-size: 14px !important;
}
.WeSpecialize {
  font-size: 24px;
  font-weight: 550;
  line-height: 1.2;
  padding: 1.5% 0;
}
.thekey {
  font-size: 15px;
  color: var(--fontColor);
}
.weProvide {
  text-align: initial;
  color: #ffff;
  font-size: 25px;
  padding: 0 9%;
  font-weight: 500;
}
.imageDiv {
  height: 25vh;
}
.paragraph {
  font-size: 15px;
  color: var(--fontColor);
}
.fontCarrier{
  font-size: 35px;
  font-weight: bold;
  float: right;
} 
.displayFlex {
  padding: 2rem;
}
.carousel-slide {
  height: 35vh;
  margin-top: 3%;
}
.carousel-caption h1 {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.1;
}
.carousel-caption .small-text {
  font-size: 12px;
  letter-spacing: 0px;
}
.footerCarrier{
  height: 25vh;
  width: 99%;
}
}


@media screen and  (max-width: 770px) {
  .image_div {
    width: 100% !important;
    height: 290px !important;
}

}


@media screen and  (max-width: 766px) {
  h4 {
    font-size: calc(0.9rem + .3vw);
  }
  .logoName h3{
    font-weight: bolder;
    font-size: 23px;
  }
  .logoName p{
    font-size: 14px;
  }
  .UsefulLink h4{
    font-size: 16px;
    font-weight: bold;
  }
  .wrapNav{
    padding: 0 5%;
  }
  .fullWidth{
    padding: 0 8%;
  }
  .weldivpt{
    padding-top: 0rem !important;
  }
  .fullImageDiv20 img{
    width: inherit;
    object-fit: cover;
    height: 100%;
    border-radius: 8px;
  }
  .fullImageDiv30 img{
    width: inherit;
    object-fit: cover;
    height: 100%;
    border-radius: 8px;
  }
.detailHeaders{
  padding-left: 0%;
}
  .slashCarrier{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .bannerCarrier{
    display: none;
  }
  .emptydiv{
    width: 40% !important;
  }
  .banner {
    background-color: var(--mainColor); 
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: right;
    padding: 5px 30px 5px 2rem;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
    color: white;
  }
  .headerFOnts{
    display: none !important;
  }
  .banner-content {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    cursor: pointer;
  }
  .phone-number, .email{
    font-size: 10px;
  }
  .navLogo {
    width: 150px;
}
.firstimageDiv{
  height: 50vh; 
}
  .weMeet_paragraph {
    font-size: 25px;
    line-height: 1;
    font-weight: 500;
  }
.image_div {
  width: 100% !important;
  height: 270px !important;
 }
 .aboutUs_weProvide p{
  color: #ffff;
  font-size: 12px;
  text-align: initial;
  font-weight: bold;
  margin-bottom: -0.6rem;
 }
 .rigText {
  font-size: 10px !important;
}
.text{
  font-size: 12px;
}
.ourAimtext {
  padding: 1rem;
  font-size: 14px;
  color: var(--mainColor);
  font-weight: bold;
}
.discoverirgfont {
  font-size: 10px;
}
.aboutfullImageDiv1 {
  width: 100%;
  height: 20vh;
}
.half_img_carrier{
  width: 100%;
  height: 20vh;
}
.missionHeadi h2 {
  color: var(--blackFontColor);
  font-size: 12px;
  font-weight: bold;
}
.fullImageDiv1 img {
  width: inherit;
  object-fit: cover;
  height: 100%;
}
.detailList{
  font-size: 10px !important;
}
.WeSpecialize {
  font-size: 14px;
  font-weight: 550;
  line-height: 1.2;
  padding: 1.5% 0;
}
.thekey {
  font-size: 10px;
  color: var(--fontColor);
}
.wellparagraph {
  font-size: 10px;
}
.wellText {
  font-size: 15px;
  font-weight: bold;
}
.weProvide {
  text-align: initial;
  color: #ffff;
  padding: 0 9%;
}
.weProvideCut p{
  font-size: 14px;
}
.imageDiv {
  height: 25vh;
}
.paragraph {
  font-size: 10px;
  color: var(--fontColor);
}
.fontCarrier{
  font-size: 35px;
  font-weight: bold;
  float: right;
} 
.displayFlex {
  padding: 2rem;
}
.carousel-slide {
  height: 23vh;
  margin-top: 3%;
}
.carousel-caption {
  top: 30%;
}
.carousel-caption h1 {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1;
}
.carousel-caption, .small-text {
  font-size: 9px;
  letter-spacing: 0px;
}
.LearnMoreBTN{
  font-size: 10px;
  gap: 0.5rem;
}
.fontCarrier{
  font-size: 18px;
}
.arrow {
  font-size: 17px;
  color: #ffff !important;
  margin-top: -1.2%;
}
.footerCarrier{
  height: 55vh;
  width: 99%;
}
.imageDiv1, .imageDiv2, .imageDiv3, .imageDiv4, .imageDiv5, .imageDiv6{
  height: 35vh;
}
.fontAndCarrier_carrier{
  padding: 1rem 2rem;
}
.missionHeadi p {
  font-size: 10px;
}
.fullImageDiv{
  width: 100%;
}
.fullImageDiv img{
  width: inherit;
  object-fit: cover;
  height: 150px;
  border-radius: 8px;
}
.usefulLinkDetail span {
  font-size: 10px;
  cursor: pointer;
}
.viewMore {
  font-size: 10px;
}
.developmental {
  font-size: 35px;
}


}


@media screen and  (max-width: 480px) {
  .slashCarrier{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .emptydiv{
    width: 40% !important;
  }
  .banner {
    background-color: var(--mainColor); 
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: right;
    padding: 5px 30px 5px 2rem;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
    color: white;
  }  .headerFOnts{
    display: none !important;
  }
  .banner-content {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    cursor: pointer;
  }
 .email{
    font-size: 8px;
  }  
  .phone-number{
    font-size: 7px;
  }
.image_div {
  width: 100% !important;
  height: 180px !important;
 }
 .sectionGap {
  margin: 0rem 0;
}

}

@media screen and  (max-width: 380px) {
  .slashCarrier{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .emptydiv{
    width: 40% !important;
  }
  .banner {
    background-color: var(--mainColor); 
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: right;
    padding: 5px 30px 5px 2rem;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
    color: white;
  }  .headerFOnts{
    display: none !important;
  }
  .banner-content {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    cursor: pointer;
  }
 .email{
    font-size: 8px;
  }  
  .phone-number{
    font-size: 7px;
  }
.image_div {
  width: 100% !important;
  height: 120px !important;
 }
 .sectionGap {
  margin: 2rem 0;
}

}